@font-face {
	font-family: 'Gotham Pro';
	src: url('~@core/assets/fonts/GothamPro-BoldItalic.eot');
	src: local('Gotham Pro Bold Italic'), local('GothamPro-BoldItalic'),
		url('~@core/assets/fonts/GothamPro-BoldItalic.eot?#iefix') format('embedded-opentype'),
		url('~@core/assets/fonts/GothamPro-BoldItalic.woff') format('woff'),
		url('~@core/assets/fonts/GothamPro-BoldItalic.ttf') format('truetype');
	font-weight: bold;
	font-style: italic;
}

@font-face {
	font-family: 'Gotham Pro';
	src: url('~@core/assets/fonts/GothamPro-Italic.eot');
	src: local('Gotham Pro Italic'), local('GothamPro-Italic'),
		url('~@core/assets/fonts/GothamPro-Italic.eot?#iefix') format('embedded-opentype'),
		url('~@core/assets/fonts/GothamPro-Italic.woff') format('woff'),
		url('~@core/assets/fonts/GothamPro-Italic.ttf') format('truetype');
	font-weight: normal;
	font-style: italic;
}

@font-face {
	font-family: 'Gotham Pro';
	src: url('~@core/assets/fonts/GothamPro-Medium.eot');
	src: local('Gotham Pro Medium'), local('GothamPro-Medium'),
		url('~@core/assets/fonts/GothamPro-Medium.eot?#iefix') format('embedded-opentype'),
		url('~@core/assets/fonts/GothamPro-Medium.woff') format('woff'),
		url('~@core/assets/fonts/GothamPro-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro';
	src: url('~@core/assets/fonts/GothamPro-BlackItalic.eot');
	src: local('Gotham Pro Black Italic'), local('GothamPro-BlackItalic'),
		url('~@core/assets/fonts/GothamPro-BlackItalic.eot?#iefix') format('embedded-opentype'),
		url('~@core/assets/fonts/GothamPro-BlackItalic.woff') format('woff'),
		url('~@core/assets/fonts/GothamPro-BlackItalic.ttf') format('truetype');
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: 'Gotham Pro';
	src: url('~@core/assets/fonts/GothamPro-Bold.eot');
	src: local('Gotham Pro Bold'), local('GothamPro-Bold'),
		url('~@core/assets/fonts/GothamPro-Bold.eot?#iefix') format('embedded-opentype'),
		url('~@core/assets/fonts/GothamPro-Bold.woff') format('woff'),
		url('~@core/assets/fonts/GothamPro-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro Narrow';
	src: url('~@core/assets/fonts/GothamProNarrow-Bold.eot');
	src: local('Gotham Pro Narrow Bold'), local('GothamProNarrow-Bold'),
		url('~@core/assets/fonts/GothamProNarrow-Bold.eot?#iefix') format('embedded-opentype'),
		url('~@core/assets/fonts/GothamProNarrow-Bold.woff') format('woff'),
		url('~@core/assets/fonts/GothamProNarrow-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro Narrow';
	src: url('~@core/assets/fonts/GothamProNarrow-Medium.eot');
	src: local('Gotham Pro Narrow Medium'), local('GothamProNarrow-Medium'),
		url('~@core/assets/fonts/GothamProNarrow-Medium.eot?#iefix') format('embedded-opentype'),
		url('~@core/assets/fonts/GothamProNarrow-Medium.woff') format('woff'),
		url('~@core/assets/fonts/GothamProNarrow-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro';
	src: url('~@core/assets/fonts/GothamPro-LightItalic.eot');
	src: local('Gotham Pro Light Italic'), local('GothamPro-LightItalic'),
		url('~@core/assets/fonts/GothamPro-LightItalic.eot?#iefix') format('embedded-opentype'),
		url('~@core/assets/fonts/GothamPro-LightItalic.woff') format('woff'),
		url('~@core/assets/fonts/GothamPro-LightItalic.ttf') format('truetype');
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: 'Gotham Pro';
	src: url('~@core/assets/fonts/GothamPro-Light.eot');
	src: local('Gotham Pro Light'), local('GothamPro-Light'),
		url('~@core/assets/fonts/GothamPro-Light.eot?#iefix') format('embedded-opentype'),
		url('~@core/assets/fonts/GothamPro-Light.woff') format('woff'),
		url('~@core/assets/fonts/GothamPro-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro';
	src: url('~@core/assets/fonts/GothamPro-Black.eot');
	src: local('Gotham Pro Black'), local('GothamPro-Black'),
		url('~@core/assets/fonts/GothamPro-Black.eot?#iefix') format('embedded-opentype'),
		url('~@core/assets/fonts/GothamPro-Black.woff') format('woff'),
		url('~@core/assets/fonts/GothamPro-Black.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro';
	src: url('~@core/assets/fonts/GothamPro.eot');
	src: local('Gotham Pro'), local('GothamPro'),
		url('~@core/assets/fonts/GothamPro.eot?#iefix') format('embedded-opentype'),
		url('~@core/assets/fonts/GothamPro.woff') format('woff'),
		url('~@core/assets/fonts/GothamPro.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Gotham Pro';
	src: url('~@core/assets/fonts/GothamPro-MediumItalic.eot');
	src: local('Gotham Pro Medium Italic'), local('GothamPro-MediumItalic'),
		url('~@core/assets/fonts/GothamPro-MediumItalic.eot?#iefix') format('embedded-opentype'),
		url('~@core/assets/fonts/GothamPro-MediumItalic.woff') format('woff'),
		url('~@core/assets/fonts/GothamPro-MediumItalic.ttf') format('truetype');
	font-weight: 500;
	font-style: italic;
}
