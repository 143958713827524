@charset "UTF-8";
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;1,300;1,400;1,500&family=Rubik:wght@500&display=swap");

a,
a:hover,
a:focus,
a:active {
  color: inherit;
  outline: none;
  text-decoration: none;
  cursor: pointer;
}

p,
label,
span,
a,
form,
select,
div,
blockquote,
cite,
figure,
figcaption,
table,
ul,
ol,
dl {
  line-height: normal;
  margin-bottom: 0px;
}

html,
body,
#root {
  height: 100%;
}

.root-wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.root-wrapper header {
  flex: 0 0 auto;
}

.root-wrapper .content-wrapper {
  position: relative;
  flex: 1 0 auto;
}

.root-wrapper footer {
  flex: 0 0 auto;
}

/*****************
SCROLL STYLIZATION
******************/
html {
  scrollbar-color: #e0e0e0 transparent;
  scrollbar-width: thin;
}

body::-webkit-scrollbar {
  background: #9FACE6;
}

::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: transparent;
}

::-webkit-scrollbar:hover,
::-webkit-scrollbar:active {
  background: #f2f2f2;
}

::-webkit-scrollbar-thumb {
  background-color: hsl(0deg, 0%, 88%);
  border-radius: 10px;
  transition: 0.2s;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #bdbdbd;
}

::-webkit-scrollbar-thumb:active {
  background-color: #038cb8;
}

::-webkit-scrollbar-button:vertical:start:decrement,
::-webkit-scrollbar-button:vertical:end:increment,
::-webkit-scrollbar-button:horizontal:start:decrement,
::-webkit-scrollbar-button:horizontal:end:increment {
  display: none;
}

/* mixins */
/* FORMS CUSTOMIZATION */
input,
textarea {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 0.5em 0.85em;
  width: 100%;
  line-height: normal;
  box-shadow: unset;
  outline: none;
  display: block;
}

input:not(:placeholder-shown),
textarea:not(:placeholder-shown) {
  border: 1px solid #e0e0e0;
}

input:hover,
textarea:hover {
  border: 1px solid #bdbdbd;
}

input:focus,
textarea:focus {
  border: 1px solid #01bfc4;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
  color: #bdbdbd;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
  color: #bdbdbd;
}

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
  color: #bdbdbd;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
  color: #bdbdbd;
}

input::-ms-input-placeholder,
textarea::-ms-input-placeholder {
  color: #bdbdbd;
}

input::placeholder,
textarea::placeholder {
  color: #bdbdbd;
}

@media (max-width: 1659px) {

  input,
  textarea {
    padding: 0.5em;
  }
}

.disabled {
  opacity: 0.5;
  filter: grayscale(1);
  pointer-events: none;
}

.inp {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 0.5em 0.85em;
  width: 100%;
}

@media (max-width: 1659px) {
  .inp {
    padding: 0.5em;
  }
}

input[type=number] {
  background-repeat: no-repeat;
  background-size: auto;
  background-position: calc(100% - 0.5em) center;
  padding: 0.5em 0.5em 0.5em 0.5em;
  max-width: 100%;
  -moz-appearance: textfield;
}

input[type=number]::-webkit-outer-spin-button,
input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number].w-100px {
  width: 100px;
}

input[type=number].price,
input[type=number].size,
input[type=number].length,
input[type=number].weight,
input[type=number].pcs,
input[type=number].temp,
input[type=number].percent {
  padding: 0.5em 1.5em 0.5em 0.5em;
}

input[type=number].price {
  background-image: url(~@core/assets/img/rub.svg);
}

input[type=number].price-per-km {
  background-image: url(~@core/assets/img/rub-km.svg);
}

input[type=number].size {
  background-image: url(~@core/assets/img/м3.svg);
}

input[type=number].length {
  background-image: url(~@core/assets/img/м.svg);
}

input[type=number].weight {
  background-image: url(~@core/assets/img/tons.svg);
}

input[type=number].pcs {
  background-image: url(~@core/assets/img/pcs.svg);
}

input[type=number].temp {
  background-image: url(~@core/assets/img/c.svg);
}

input[type=number].percent {
  background-image: url(~@core/assets/img/percent.svg);
}

input[type=number].distance {
  background-image: url(~@core/assets/img/km.svg);
}

input[type=radio] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  border: 2px solid #e0e0e0;
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  background-color: #fff;
  padding: 0px;
  position: relative;
}

input[type=radio]::before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  width: 0px;
  height: 0px;
  border-radius: 50%;
  background-color: #01bfc4;
  transform: translate(-50%, -50%);
  transition: 0.1s ease-in-out;
}

input[type=radio]:checked {
  border: 2px solid #01bfc4;
}

input[type=radio]:checked::before {
  width: 0.75rem;
  height: 0.75rem;
}

input[type=checkbox] {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  width: 1.25rem;
  height: 1.25rem;
  padding: 0px;
  border-radius: 2px;
  border: 1px solid #e0e0e0;
  background-image: url(~@core/assets/img/mark.svg);
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
}

input[type=checkbox]:checked {
  border: 1px solid #038cb8;
  background-color: #038cb8;
}

input::-webkit-calendar-picker-indicator {
  background-image: url(~@core/assets/img/calendar-green2.svg);
}

label {
  display: flex;
  align-items: center;
}

label span {
  flex: 1;
}

select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  width: fit-content;
  outline: none;
  line-height: normal;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 0.6em 1.7em 0.6em 0.7em;
  background: url(~@core/assets/img/caret-down.svg) no-repeat calc(100% - 0.5em) center;
  background-size: 1em 0.5em;
}

.react-select__control {
  border-radius: 8px !important;
  border: 1px solid #e0e0e0 !important;
}

.react-select__control--is-focused {
  border: 1px solid #01bfc4 !important;
  box-shadow: none !important;
}

.react-select__value-container {
  padding: 0.5em 0em 0.5em 0.85em !important;
}

.react-select__indicator {
  color: #01bfc4 !important;
}

.react-select__indicator-separator {
  display: none;
}

.react-select__menu {
  font-size: 0.9em;
  min-width: 150px;
}

.react-select__option:hover,
.react-select__option:focus,
.react-select__option:active,
.react-select__option--is-focused {
  background-color: #d5f6ef !important;
  color: #333333;
}

.react-select__option--is-selected {
  background-color: #01bfc4 !important;
  color: #fff;
}

.react-select__input-container {
  margin: 0px !important;
  padding: 0px !important;
}

.react-select-simple__control {
  border-radius: 0px !important;
  border: none !important;
  min-height: unset !important;
}

.react-select-simple__control--is-focused {
  border: none !important;
  box-shadow: none !important;
}

.react-select-simple__value-container {
  padding: 0px !important;
}

.react-select-simple__indicator {
  color: #01bfc4 !important;
  padding: 0px !important;
}

.react-select-simple__indicator-separator {
  display: none;
}

.react-select-simple__menu {
  font-size: 0.9em;
  min-width: 150px;
}

.react-select-simple__option:hover,
.react-select-simple__option:focus,
.react-select-simple__option:active,
.react-select-simple__option--is-focused {
  background-color: #d5f6ef !important;
  color: #333333;
}

.react-select-simple__option--is-selected {
  background-color: #01bfc4 !important;
  color: #fff;
}

.react-select-simple__input-container {
  margin: 0px !important;
  padding: 0px !important;
}

.right-alignment .react-select__menu,
.right-alignment .react-select-simple__menu {
  right: 0px;
  left: unset;
}

.password {
  position: relative;
}

.password input {
  position: relative;
  z-index: 1;
}

.password button {
  position: absolute;
  top: 0px;
  right: 1em;
  height: 100%;
  z-index: 2;
  width: 2.5em;
}

.password button img {
  width: 100%;
  height: 100%;
  object-fit: scale-down;
}

.form-search {
  position: relative;
}

.form-search input {
  padding-left: 3em;
}

.form-search button {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 3em;
  height: 100%;
}

.input-file {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  height: 2.5em;
  width: 2.5em;
  border-radius: 0.25em;
  transition: 0.2s;
}

.input-file:hover {
  background-color: #038cb8;
}

.input-file:hover img {
  filter: brightness(0) invert(1);
}

.input-file input {
  position: absolute;
  z-index: -1;
  opacity: 0;
  width: 1px;
  height: 1px;
  top: 0px;
  left: 0px;
  padding: 0px;
}

.input-file img {
  height: 2em;
  width: 2em;
  object-fit: scale-down;
  object-position: center;
  transition: 0.2s;
}

.input-file .ind {
  position: absolute;
  bottom: -0.5em;
  right: -0.25em;
  width: 1.2em;
  height: 1.2em;
  font-size: 0.9em;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #02537d;
  color: #01bfc4;
  font-weight: 600;
}

.input-file .ind:empty {
  display: none;
}

button {
  background: none;
  border: none;
  padding: unset;
  display: block;
  font-family: inherit;
  outline: none;
  color: inherit;
}

.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  padding: 0.5em 2em;
  text-align: center;
  transition: 0.5s;
  width: fit-content;
  font-size: 1em;
  line-height: normal;
  font-weight: 500;
}

.btn-1 {
  border: 1px solid #01bfc4;
  background-color: #01bfc4;
  color: #fff;
}

.btn-1:hover,
.btn-1:focus,
.btn-1:active {
  color: #fff;
  background-color: #02537d;
  border: 1px solid #02537d;
}

.btn-1:disabled {
  color: #c4c4c4;
  background-color: #7a7878;
  border: 1px solid #7a7878;
}

.btn-2 {
  background-color: #038cb8;
  border: 1px solid #038cb8;
  color: #fff;
}

.btn-2:hover,
.btn-2:focus,
.btn-2:active {
  color: #fff;
  background-color: #02537d;
  border: 1px solid #02537d;
}

.btn-2:disabled {
  color: #c4c4c4;
  background-color: #7a7878;
  border: 1px solid #7a7878;
}

.btn-2 svg line {
  stroke: #fff;
}

.btn-3 {
  background-color: transparent;
  border: 1px solid #01bfc4;
  color: #01bfc4;
}

.btn-3:hover,
.btn-3:focus,
.btn-3:active {
  color: #038cb8;
  background-color: transparent;
  border: 1px solid #038cb8;
}

.btn-3:disabled {
  color: #7a7878;
  background-color: transparent;
  border: 1px solid #7a7878;
}

.btn-4 {
  background-color: transparent;
  border: 1px solid #038cb8;
  color: #038cb8;
}

.btn-4:hover,
.btn-4:focus,
.btn-4:active {
  color: #01bfc4;
  background-color: transparent;
  border: 1px solid #01bfc4;
}

.btn-4:disabled {
  color: #7a7878;
  background-color: transparent;
  border: 1px solid #7a7878;
}


.btn-5 {
  border: 1px solid #f7ca01;
  background-color: #f7ca01;
  color: #fff;
}

.btn-5:hover,
.btn-5:focus,
.btn-5:active {
  color: #fff;
  background-color: #f7ca01;
  border: 1px solid #f7ca01;
}

.btn-5:disabled {
  color: #c4c4c4;
  background-color: #7a7878;
  border: 1px solid #7a7878;
}

.btn-view svg rect {
  fill: #038cb8;
}

.btn-notice {
  width: 2.125em;
  height: 2.125em;
  border-radius: 50%;
  background-color: #02537d;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 2px;
}

.btn-notice svg {
  width: 1.3em;
  height: 1.3em;
  fill: none;
}

.btn-notice svg path.fill-color {
  fill: #828282;
  transition: 0.2s ease-in-out;
}

.btn-notice:hover svg path.fill-color,
.btn-notice:focus svg path.fill-color {
  fill: #01bfc4;
}

h1,
h2,
h3,
h4,
h5 {
  font-family: "Gotham Pro";
}

h1 {
  text-align: left;
  font-weight: 700;
  margin-bottom: 1em;
  font-size: 1.875em;
}

@media (max-width: 575px) {
  h1 {
    font-size: 1.5em;
  }
}

h1.main {
  text-align: center;
  font-size: 3em;
  color: #01bfc4;
  width: fit-content;
  background-color: #fff;
  padding: 0.1em 1em;
}

@media (max-width: 1199px) {
  h1.main {
    font-size: 2.5em;
    padding: 0.1em 0.5em;
  }
}

@media (max-width: 1199px) {
  h1.main {
    font-size: 2em;
  }
}

h2 {
  text-align: center;
  font-size: 1.75em;
  font-weight: 700;
  margin-bottom: 1em;
  color: #02537d;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  h2 {
    font-size: 1.5em;
    margin-bottom: 0.75em;
  }
}

h3 {
  text-align: center;
  font-size: 1.75em;
  font-weight: 700;
  margin-bottom: 1em;
  color: #02537d;
}

@media (max-width: 1199px) {
  h3 {
    font-size: 1.5em;
  }
}

h4 {
  text-align: left;
  font-size: 1.5em;
  font-weight: 700;
}

h5 {
  text-align: left;
  color: #02537d;
  font-size: 1.25em;
  font-weight: 700;
  margin-bottom: 1em;
}

.title-font {
  font-family: "Gotham Pro";
}

.sec-font {
  font-family: "Rubik";
}

.fs-07 {
  font-size: 0.7em;
}

.fs-075 {
  font-size: 0.75em;
}

.fs-08 {
  font-size: 0.8em;
}

.fs-09 {
  font-size: 0.9em;
}

.fs-11 {
  font-size: 1.1em;
}

.fs-12 {
  font-size: 1.2em;
}

.fs-13 {
  font-size: 1.3em;
}

.fs-14 {
  font-size: 1.4em;
}

.fs-15 {
  font-size: 1.5em;
}

.fs-16 {
  font-size: 1.6em;
}

.fs-17 {
  font-size: 1.7em;
}

.fs-18 {
  font-size: 1.8em;
}

.fs-19 {
  font-size: 1.9em;
}

.fs-20 {
  font-size: 2em;
}

.fs-25 {
  font-size: 2.5em;
}

.fw-3 {
  font-weight: 300;
}

.fw-4 {
  font-weight: 400;
}

.fw-5 {
  font-weight: 500;
}

.fw-6 {
  font-weight: 600;
}

.fw-7 {
  font-weight: 700;
}

.fw-8 {
  font-weight: 800;
}

.fw-9 {
  font-weight: 900;
}

.gray-2 {
  color: #4f4f4f;
}

.gray-3 {
  color: #828282;
}

.gray-4 {
  color: #bdbdbd;
}

.green {
  color: #01bfc4;
}

.blue {
  color: #038cb8;
}

.dark-blue {
  color: #02537d;
}

.text-color {
  color: #545454;
}

.red {
  color: #d17777;
}

.white {
  color: #fff;
}

.black {
  color: #000;
}

.rotate-180 {
  transform: rotate(180deg);
}

.bb-1 {
  border-bottom: 1px solid;
}

.shad-box {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
}

.w-fit {
  width: fit-content;
}

.flex-1 {
  flex: 1;
}

.min-150 {
  min-width: 150px;
}

@media (max-width: 1399px) {
  .min-150 {
    min-width: 125px;
  }
}

@media (min-width: 1660px) {
  .container {
    width: 1596px;
    max-width: 1596px;
  }
}

.mb-6 {
  margin-bottom: 6em;
}

@media (max-width: 575px) {
  .mb-6 {
    margin-bottom: 4em;
  }
}

hr {
  width: 100%;
  height: 1px;
  border: none;
  background-color: #e0e0e0;
  margin: 0px;
  opacity: 1;
}

.box {
  border: 1px solid #e0e0e0;
  border-radius: 6px;
  background-color: #fff;
  padding: 2em;
}

.marked-list {
  list-style: none;
  padding-left: 0px;
}

.marked-list li {
  padding-left: 1.5em;
  background: url(~@core/assets/img/checkbox.svg) no-repeat left center;
  background-size: 1em;
  margin-bottom: 0.5em;
}

/* ICONS */
.icon-10 svg,
.icon-15 svg,
.icon-20 svg,
.icon-25 svg {
  width: 100%;
  height: 100%;
}

.icon-10 {
  width: 1em;
  height: 1em;
}

.icon-15 {
  width: 1.5em;
  height: 1.5em;
}

.icon-20 {
  width: 2em;
  height: 2em;
}

.icon-25 {
  width: 2.5em;
  height: 2.5em;
}

body {
  margin: 0;
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #545454;
  font-size: 16px;
  font-weight: 400;
  background-color: rgba(213, 246, 239, 0.2);
}

@media (max-width: 1199px) {
  body {
    font-size: 14px;
  }
}

@media (max-width: 575px) {
  body {
    font-size: 12px;
  }
}

header {
  z-index: 1900;
  background: #ffffff;
  box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.07);
  position: sticky;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 70px;
}

@media (max-width: 1199px) {
  header {
    height: 55px;
  }
}

@media (max-width: 991px) {
  header {
    height: 50px;
  }
}

@media (max-width: 575px) {
  header {
    padding: 0.75em 0;
  }
}

header .container {
  display: flex;
  align-items: center;
  height: 100%;
}

header .container nav {
  display: flex;
  align-items: center;
  height: 100%;
}

header .container nav a {
  font-family: "Gotham Pro";
  color: #545454;
  padding: 0.5em 0 0;
  margin: 0 1em;
  font-size: 1.2em;
  font-weight: 500;
  transition: 0.2s;
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 8px solid transparent;
}

header .container nav a.active,
header .container nav a:hover,
header .container nav a:focus {
  border-bottom: 8px solid #01bfc4;
}

@media (max-width: 1199px) {

  header .container nav a.active,
  header .container nav a:hover,
  header .container nav a:focus {
    border-bottom: 5px solid #01bfc4;
  }
}

@media (max-width: 1199px) {
  header .container nav a {
    border-bottom: 5px solid transparent;
    margin: 0 0.75em;
  }
}

@media (max-width: 991px) {
  header .container {
    justify-content: space-between;
  }
}

header .logo {
  max-width: 60px;
  max-height: 30px;
  object-fit: scale-down;
  object-position: center;
}

@media (max-width: 576px) {
  header .logo {
    max-width: 40px;
    max-height: 20px;
  }
}

header .select {
  max-width: 160px;
}

header .icon {
  width: 2em;
  height: 2em;
  color: #01bfc4;
}

main {
  min-height: calc(100vh - 70px - 500px);
  position: relative;
}

main.bg-white {
  background-color: #fff;
}

main.bg-gray {
  background-color: #fafafa;
}

@media (max-width: 1199px) {
  main {
    min-height: calc(100vh - 55px - 130px);
  }
}

@media (max-width: 991px) {
  main {
    min-height: calc(100vh - 50px - 140px);
  }
}

@media (max-width: 767px) {
  main {
    min-height: calc(100vh - 50px - 180px);
  }
}

#sec-1 {
  position: relative;
  z-index: 1;
}

#sec-1 #slider-1 .img {
  width: 100%;
  height: 430px;
  object-fit: cover;
  border-radius: 1em;
}

@media (max-width: 1659px) {
  #sec-1 #slider-1 .img {
    height: 360px;
  }
}

@media (max-width: 1199px) {
  #sec-1 #slider-1 .img {
    height: 300px;
  }
}

@media (max-width: 991px) {
  #sec-1 #slider-1 .img {
    height: 340px;
  }
}

@media (max-width: 767px) {
  #sec-1 #slider-1 .img {
    height: 245px;
  }
}

@media (max-width: 767px) {
  #sec-1 #slider-1 .img {
    height: 45vw;
  }
}

#sec-1 .box {
  background-color: #fff;
  padding: 2em 3em;
  border-radius: 1em;
  border: 1px solid rgba(213, 246, 239, 0.75);
}

@media (max-width: 1659px) {
  #sec-1 .box {
    padding: 1em 2em;
  }
}

@media (max-width: 1199px) {
  #sec-1 .box {
    padding: 0.75em 2em;
  }
}

@media (max-width: 991px) {
  #sec-1 .box {
    width: calc(50% - 1em);
  }
}

@media (max-width: 767px) {
  #sec-1 .box {
    padding: 0.75em 1em;
    width: calc(50% - 1em);
  }
}

@media (max-width: 575px) {
  #sec-1 .box {
    padding: 0.75em;
    width: calc(50% - 0.5em);
    font-size: 10px;
  }
}

.carousel-indicators [data-bs-target] {
  width: 0.75em;
  height: 0.75em;
  border-radius: 50%;
  margin-right: 0.25em;
  margin-left: 0.25em;
  background-color: #e0e0e0;
  opacity: 1;
}

.carousel-indicators [data-bs-target].active {
  background-color: #038cb8;
}

.carousel-item.active::before {
  content: "";
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  border-radius: 1em;
  background-color: rgba(0, 0, 0, 0.3);
}

.carousel-item__text {
  position: absolute;
  top: 34px;
  right: 42px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 10px;
}

@media (max-width: 575px) {
  .carousel-item__text {
    top: 20px;
    right: 20px;
  }
}

.carousel-item__text .title {
  font-family: "Gotham Pro", serif;
  font-weight: 900;
  font-size: 2.25em;
  line-height: 105%;
  color: #FFF;
  max-width: 413px;
  text-align: right;
  margin: 0;
}

@media (max-width: 575px) {
  .carousel-item__text .title {
    max-width: 300px;
    font-size: 1.75em;
  }
}

.carousel-item__text .description {
  font-family: "Roboto", serif;
  font-weight: 500;
  font-size: 1.25em;
  line-height: 120%;
  color: #FFF;
  max-width: 351px;
  text-align: right;
}

@media (max-width: 575px) {
  .carousel-item__text .description {
    max-width: 270px;
    font-size: 0.85em;
  }
}

/* SECTION 2 */
#sec-2 {
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1659px) {
  #sec-2 form .fs-15 {
    font-size: 1.4em;
  }
}

@media (max-width: 991px) {
  #sec-2 form .fs-15 {
    font-size: 1.3em;
  }
}

@media (max-width: 767px) {
  #sec-2 form button {
    width: 100%;
  }
}

/* SECTION 3 */
/* card */
.card-mini {
  overflow: hidden;
  border-radius: 0.5em;
  background: #fff;
  box-shadow: 1px 4px 9px rgba(1, 191, 196, 0.34);
  padding: 2em;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
}

@media (max-width: 991px) {
  .card-mini {
    padding: 1.5em;
    font-size: 12px;
  }
}

@media (max-width: 575px) {
  .card-mini {
    padding: 0.75em;
  }
}

.card-mini__wrapper {
  /* height: 100%; */
}

.card-mini .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "Gotham Pro";
  font-size: 1.5em;
  font-weight: bold;
}

@media (max-width: 575px) {
  .card-mini .title {
    font-size: 1.2em;
  }
}

.card-mini .btn-1 {
  font-size: 1.2em;
  width: 100%;
}

@media (max-width: 575px) {
  .card-mini .btn-1 {
    font-size: 1em;
  }
}

.card-mini .icon {
  color: #038cb8;
}

.card-mini .icon svg {
  width: 1.5em;
  height: 1.5em;
}

.card-mini .dropdown {
  position: absolute;
  top: 0.5em;
  right: 0px;
}

/* SECTION 4 */
#sec-4 {
  position: relative;
  z-index: 0;
  overflow: hidden;
  height: 450px;
}

@media (max-width: 1199px) {
  #sec-4 {
    height: 400px;
  }
}

@media (max-width: 991px) {
  #sec-4 {
    background-color: #fff;
    height: fit-content;
    padding: 2em 0;
  }
}

#sec-4::before {
  content: "";
  z-index: 0;
  position: absolute;
  left: 0%;
  top: 0%;
  height: 100%;
  width: 85%;
  background-color: #fff;
}

@media (max-width: 991px) {
  #sec-4::before {
    content: unset;
  }
}

#sec-4::after {
  content: "";
  z-index: 1;
  position: absolute;
  right: 5%;
  top: 4%;
  height: 450px;
  width: 450px;
  background-color: #fff;
  border-top: 30px solid #d5f6ef;
  border-right: 30px solid #d5f6ef;
  transform: rotate(56deg) skewX(20deg);
}

@media (max-width: 1199px) {
  #sec-4::after {
    height: 400px;
    width: 400px;
  }
}

@media (max-width: 991px) {
  #sec-4::after {
    content: unset;
  }
}

#sec-4 .container {
  position: relative;
  z-index: 2;
}

/* SECTION 5 */
#sec-5 .scheme {
  width: 100%;
  height: 645px;
  object-fit: scale-down;
}

@media (max-width: 1399px) {
  #sec-5 .scheme {
    height: 580px;
  }
}

@media (max-width: 1199px) {
  #sec-5 .scheme {
    height: 460px;
  }
}

#sec-5 .title {
  font-family: "Gotham Pro";
  font-size: 1.25em;
  font-weight: 700;
  margin-bottom: 1em;
}

#sec-5 .title-left span {
  border-bottom: 4px solid #01bfc4;
}

@media (max-width: 1199px) {
  #sec-5 .title-left span {
    border-bottom: 2px solid #01bfc4;
  }
}

#sec-5 .title-right span {
  border-bottom: 4px solid #038cb8;
}

@media (max-width: 1199px) {
  #sec-5 .title-right span {
    border-bottom: 2px solid #038cb8;
  }
}

#sec-5 .ribbon {
  width: 2.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 1.75em;
  font-weight: 700;
  text-transform: uppercase;
  padding: 1em 0;
  position: relative;
  height: 645px;
}

@media (max-width: 1399px) {
  #sec-5 .ribbon {
    height: 580px;
  }
}

@media (max-width: 1199px) {
  #sec-5 .ribbon {
    height: 460px;
  }
}

#sec-5 .ribbon-left {
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  background-color: #01bfc4;
}

#sec-5 .ribbon-left::before {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 0px;
  width: 100%;
  border-left: 1.4rem solid transparent;
  border-right: 1.4rem solid transparent;
  border-bottom: 1rem solid #01bfc4;
}

#sec-5 .ribbon-left::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 0px;
  width: 100%;
  border-bottom: 1rem solid transparent;
  border-left: 1.4rem solid #01bfc4;
  border-right: 1.4rem solid #01bfc4;
}

#sec-5 .ribbon-right {
  writing-mode: vertical-lr;
  transform: rotate(360deg);
  background-color: #038cb8;
}

#sec-5 .ribbon-right::before {
  content: "";
  position: absolute;
  top: 100%;
  left: 0px;
  width: 100%;
  border-top: 1rem solid #038cb8;
  border-left: 1.4rem solid transparent;
  border-right: 1.4rem solid transparent;
}

#sec-5 .ribbon-right::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 0px;
  width: 100%;
  border-left: 1.4rem solid #038cb8;
  border-right: 1.4rem solid #038cb8;
  border-top: 1rem solid transparent;
}

@media (max-width: 1199px) {
  #sec-5 .row {
    font-size: 12px;
  }
}

#sec-5 .point {
  display: flex;
  align-items: center;
  margin-top: 1em;
}

#sec-5 .point:nth-child(odd) {
  --color: #01bfc4;
}

#sec-5 .point:nth-child(even) {
  --color: #038cb8;
}

#sec-5 .point .icon {
  background-color: var(--color);
  width: 6em;
  height: 6em;
  border-radius: 50%;
  margin-right: 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 575px) {
  #sec-5 .point .icon {
    width: 4em;
    height: 4em;
  }
}

#sec-5 .point .icon img {
  width: 3em;
  height: 3em;
}

@media (max-width: 575px) {
  #sec-5 .point .icon img {
    width: 2em;
    height: 2em;
  }
}

#sec-5 .point .text {
  flex: 1;
  margin-right: 1em;
}

#sec-5 .point .text .title span {
  border-bottom: 4px solid var(--color);
}

@media (max-width: 767px) {
  #sec-5 .point .text .title span {
    border-bottom: 2px solid var(--color);
  }
}

@media (max-width: 575px) {
  #sec-5 .point .text .title {
    font-size: 1.1em;
    margin-bottom: 0.5em;
  }
}

#sec-5 .point .ribbon {
  height: fit-content;
  min-height: 120px;
  font-size: 0.8em;
  width: 2em;
  font-weight: 500;
  background-color: var(--color);
}

@media (max-width: 575px) {
  #sec-5 .point .ribbon {
    min-height: 90px;
  }
}

#sec-5 .point .ribbon::before {
  border-left: 1em solid transparent;
  border-right: 1em solid transparent;
  border-bottom: 1em solid var(--color);
}

#sec-5 .point .ribbon::after {
  border-bottom: 1em solid transparent;
  border-left: 1em solid var(--color);
  border-right: 1em solid var(--color);
}

/* SECTION 6 - Новости*/
.news-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 285px);
  grid-gap: 1.5em 1.5em;
}

@media (max-width: 1399px) {
  .news-grid {
    grid-template-rows: repeat(2, 240px);
  }
}

@media (max-width: 1199px) {
  .news-grid {
    grid-template-rows: repeat(2, 200px);
  }
}

@media (max-width: 991px) {
  .news-grid {
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(3, 215px);
  }
}

@media (max-width: 767px) {
  .news-grid {
    grid-gap: 0.5em 0.5em;
    grid-template-rows: repeat(3, 180px);
  }
}

@media (max-width: 575px) {
  .news-grid {
    grid-template-columns: repeat(1, 1fr);
    grid-template-rows: repeat(2, 90vw);
    grid-gap: 0px;
    grid-auto-columns: 0px;
    grid-auto-rows: 0px;
  }
}

.news-grid article {
  font-size: 20px;
  grid-column: span 1;
  grid-row: span 1;
}

@media (max-width: 1399px) {
  .news-grid article {
    font-size: 18px;
  }
}

@media (max-width: 1199px) {
  .news-grid article {
    font-size: 16px;
  }
}

@media (max-width: 991px) {
  .news-grid article {
    font-size: 12px;
  }
}

@media (max-width: 575px) {
  .news-grid article {
    margin-bottom: 1em;
  }
}

.news-grid article figure {
  height: 100%;
  position: relative;
  overflow: hidden;
}

.news-grid article figure img {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.news-grid article figure figcaption {
  z-index: 2;
  position: absolute;
  top: calc(100% - 60px);
  left: 0px;
  width: 100%;
  transition: 0.2s ease-in-out;
}

@media (max-width: 1199px) {
  .news-grid article figure figcaption {
    top: calc(100% - 45px);
  }
}

@media (max-width: 991px) {
  .news-grid article figure figcaption {
    top: calc(100% - 85px);
  }
}

.news-grid article figure figcaption .title {
  display: block;
  background-color: #01bfc4;
  height: 60px;
  color: #fff;
  font-family: "Gotham Pro";
  font-size: 1.2em;
  font-weight: 700;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 0 1em;
  line-height: 60px;
}

@media (max-width: 1199px) {
  .news-grid article figure figcaption .title {
    height: 45px;
    line-height: 45px;
  }
}

@media (max-width: 991px) {
  .news-grid article figure figcaption .title {
    height: 35px;
    line-height: 35px;
  }
}

.news-grid article figure figcaption .hidden-div {
  background: rgba(0, 0, 0, 0.69);
  height: 90px;
  padding: 0 1em;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 1199px) {
  .news-grid article figure figcaption .hidden-div {
    height: 70px;
  }
}

@media (max-width: 991px) {
  .news-grid article figure figcaption .hidden-div {
    height: 50px;
  }
}

.news-grid article figure figcaption .hidden-div .text {
  font-size: 1em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.3em;
  height: 2.6em;
  color: #fff;
}

.news-grid article figure:hover figcaption {
  top: calc(100% - 150px);
}

@media (max-width: 1199px) {
  .news-grid article figure:hover figcaption {
    top: calc(100% - 115px);
  }
}

@media (max-width: 991px) {
  .news-grid article figure:hover figcaption {
    top: calc(100% - 85px);
  }
}

.news-grid article:first-child {
  grid-row: span 2;
}

@media (max-width: 575px) {
  .news-grid article:first-child {
    grid-row: span 1;
  }
}

article.mini {
  position: relative;
}

article.mini figure {
  margin-bottom: 1em;
}

@media (max-width: 1199px) {
  article.mini figure {
    margin-bottom: 0.5em;
  }
}

article.mini figure img {
  width: 100%;
  height: 285px;
  object-fit: cover;
  margin-bottom: 1em;
}

@media (max-width: 1659px) {
  article.mini figure img {
    height: 235px;
  }
}

@media (max-width: 1399px) {
  article.mini figure img {
    height: 200px;
  }
}

@media (max-width: 1199px) {
  article.mini figure img {
    height: 170px;
    margin-bottom: 0.5em;
  }
}

@media (max-width: 767px) {
  article.mini figure img {
    height: 190px;
  }
}

@media (max-width: 575px) {
  article.mini figure img {
    height: 34vw;
  }
}

article.mini figure figcaption {
  font-size: 1.5em;
  font-family: "Gotham Pro";
  font-weight: 700;
  color: #545454;
}

@media (max-width: 575px) {
  article.mini figure figcaption {
    font-size: 1.25em;
  }
}

article.mini .text {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2em;
  height: 6em;
  font-size: 0.9em;
}

article.mini .stretched-link:hover,
article.mini .stretched-link:focus {
  color: #01bfc4;
}

article.full figure {
  display: inline-block;
  width: 100%;
}

article.full figure img {
  width: 50%;
  float: left;
  margin: 0 1em 1em 0;
}

@media (max-width: 575px) {
  article.full figure img {
    width: 100%;
    float: unset;
    margin: 0 0 1em 0;
  }
}

article.full figure figcaption p {
  margin-bottom: 1em;
}

/* SECTION 7 */
#sec-7 .switch {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

#sec-7 .switch button {
  flex-grow: 1;
  background-color: #eef3f3;
  color: #575e62;
  font-family: "Rubik";
  font-size: 1.2em;
  font-weight: 500;
  text-transform: uppercase;
  padding: 2em 6em 2em 2em;
  border-radius: 8px 8px 0px 0px;
  position: relative;
  z-index: 1;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

#sec-7 .switch button span {
  position: relative;
  z-index: 1;
}

#sec-7 .switch button img {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0px;
  height: 160%;
  z-index: 0;
  opacity: 50%;
}

#sec-7 .switch button.active {
  background-color: #01bfc4;
  color: #fff;
  z-index: 2;
}

@media (max-width: 767px) {
  #sec-7 .switch button.active {
    box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
  }
}

@media (max-width: 991px) {
  #sec-7 .switch button {
    padding: 1em 4em 1em 2em;
  }
}

@media (max-width: 767px) {
  #sec-7 .switch button {
    padding: 1em 2em;
    text-align: center;
  }

  #sec-7 .switch button img {
    display: none;
  }
}

@media (max-width: 575px) {
  #sec-7 .switch button {
    padding: 0.75em 1em;
  }
}

#sec-7 form {
  background-color: #fff;
  border: 1px solid #d5f6ef;
  border-bottom-left-radius: 1em;
  border-bottom-right-radius: 1em;
  padding: 2em 3em;
}

@media (max-width: 1199px) {
  #sec-7 form {
    padding: 2em;
  }
}

@media (max-width: 575px) {
  #sec-7 form {
    padding: 1em;
  }
}

#sec-7 form label {
  font-size: 1.2em;
}

@media (max-width: 1199px) {
  #sec-7 form label {
    font-size: 1.1em;
  }
}

#sec-7 form input,
#sec-7 form select,
#sec-7 form button,
#sec-7 form .fs-15 {
  font-size: 1.5em;
}

@media (max-width: 1659px) {

  #sec-7 form input,
  #sec-7 form select,
  #sec-7 form button,
  #sec-7 form .fs-15 {
    font-size: 1.4em;
  }
}

@media (max-width: 1199px) {

  #sec-7 form input,
  #sec-7 form select,
  #sec-7 form button,
  #sec-7 form .fs-15 {
    font-size: 1.3em;
  }
}

@media (max-width: 575px) {

  #sec-7 form input,
  #sec-7 form select,
  #sec-7 form button,
  #sec-7 form .fs-15 {
    font-size: 1.1em;
  }
}

#sec-7 form .icon {
  width: 2.5em;
  height: 2em;
}

@media (max-width: 767px) {
  #sec-7 form .icon {
    width: 2em;
    height: 2em;
  }
}

/* SECTION 8 */
#sec-8 .icon {
  width: 1.5em;
  height: 1.5em;
}

#sec-8 .icon svg {
  width: 100%;
  height: 100%;
}

#sec-8 .user-contacts {
  background-color: #f2f6f6;
  padding: 2em 4em;
  border-radius: 6px;
}

@media (max-width: 1659px) {
  #sec-8 .user-contacts {
    padding: 2em;
  }
}

@media (max-width: 767px) {
  #sec-8 .user-contacts {
    align-items: center;
    padding: 1em;
  }
}

#sec-8 .user-contacts img.logo {
  width: 220px;
  height: 220px;
  object-fit: cover;
  border-radius: 50%;
  display: block;
  margin: auto;
}

@media (max-width: 767px) {
  #sec-8 .user-contacts img.logo {
    width: 150px;
    height: 150px;
  }
}

@media (max-width: 575px) {
  #sec-8 .user-contacts img.logo {
    width: 90px;
    height: 90px;
  }
}

#sec-8 .user-contacts img.sample {
  width: 100%;
  height: 220px;
  object-fit: scale-down;
  opacity: 50%;
  display: block;
  margin: auto;
}

@media (max-width: 767px) {
  #sec-8 .user-contacts img.sample {
    height: 150px;
  }
}

@media (max-width: 575px) {
  #sec-8 .user-contacts img.sample {
    height: 90px;
  }
}

/* SECTION 9 */
@media (max-width: 991px) {
  #sec-9 {
    padding-bottom: 80px;
  }
}

@media (max-width: 991px) {
  #sec-9 form fieldset[data-show=true] {
    display: block;
  }

  #sec-9 form fieldset[data-show=false] {
    display: none;
  }

  #sec-9 form .box {
    border: unset;
    border-radius: unset;
    background-color: unset;
    padding: 0px;
  }
}

#sec-9 form .mobile-btns {
  position: fixed;
  bottom: 0px;
  left: 0px;
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 1px 15px rgba(0, 0, 0, 0.1);
  height: 100px;
  padding: 1em;
}

#sec-9 form .mobile-btns .container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

@media (max-width: 575px) {
  #sec-9 form .mobile-btns .container {
    padding: 0px;
  }
}

#sec-9 form [data-warning=true] {
  color: #d17777;
}

#sec-9 form [data-warning=true] .gray-4 {
  color: #d17777;
}

#sec-9 form [data-warning=true] input {
  border-color: #d17777;
}

#sec-9 nav.contents ol {
  counter-reset: li;
  list-style: none;
  padding-left: 0px;
  position: relative;
}

#sec-9 nav.contents ol::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 1em;
  height: 90%;
  border-left: 2px dashed #e0e0e0;
  z-index: 1;
}

#sec-9 nav.contents ol li {
  padding-left: 4em;
  position: relative;
  z-index: 2;
}

#sec-9 nav.contents ol li:not(:last-child) {
  margin-bottom: 1.25em;
}

@media (max-width: 1399px) {
  #sec-9 nav.contents ol li {
    padding-left: 3em;
  }
}

#sec-9 nav.contents ol li a {
  font-family: "Gotham Pro";
  font-size: 1.25em;
  font-weight: 500;
  transition: 0.2s;
  line-height: 1.75em;
}

@media (max-width: 1399px) {
  #sec-9 nav.contents ol li a {
    font-size: 1.1em;
  }
}

#sec-9 nav.contents ol li a::before {
  content: counter(li);
  counter-increment: li;
  position: absolute;
  left: 0;
  width: 1.75em;
  height: 1.75em;
  border-radius: 50%;
  background: #e0e0e0;
  color: #fff;
  line-height: 2em;
  text-align: center;
  font-weight: 700;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}

#sec-9 nav.contents ol li a.active {
  color: #01bfc4;
}

#sec-9 nav.contents ol li a.filled::before {
  background: #01bfc4;
}

#sec-9 nav.contents ol li .fs-09 span:empty {
  display: none;
}

#sec-9 aside {
  position: sticky;
  top: 70px;
  left: 0px;
  width: 100%;
}

#sec-9 .mobile-indicators {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 2em;
}

#sec-9 .mobile-indicators button {
  font-family: "Gotham Pro";
  position: relative;
  font-size: 1.25em;
  width: 1.75em;
  height: 1.75em;
  border-radius: 50%;
  background-color: #e0e0e0;
  color: #fff;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

#sec-9 .mobile-indicators button:not(:first-child) {
  margin-left: 1em;
}

#sec-9 .mobile-indicators button:not(:first-child)::after {
  content: "";
  position: absolute;
  right: 100%;
  bottom: calc(50% - 2px);
  width: 1em;
  border-bottom: 2px dashed #e0e0e0;
}

#sec-9 .mobile-indicators button.active {
  background-color: #01bfc4;
}

@media (max-width: 1399px) {
  #sec-9 .fs-12 {
    font-size: 1em;
  }

  #sec-9 .fs-15 {
    font-size: 1.3em;
  }
}

@media (max-width: 1199px) {
  #sec-9 .fs-15 {
    font-size: 1.2em;
  }
}

@media (max-width: 991px) {
  #sec-9 .fs-12 {
    font-size: 1.2em;
  }
}

.patterns.box {
  overflow-y: auto;
  max-height: 235px;
}

.patterns.box>div:not(:first-child) {
  margin-top: 1em;
}

/* SECTION 10 */
#sec-10 {
  position: relative;
  z-index: 1;
}

.entrance-bg {
  position: absolute;
  top: 0px;
  right: 0px;
  height: 100%;
  width: calc((100% - 1600px) / 2 + 600px);
  object-fit: cover;
  z-index: 0;
}

@media (max-width: 1659px) {
  .entrance-bg {
    width: calc((100% - 1320px) / 2 + 500px);
  }
}

@media (max-width: 1399px) {
  .entrance-bg {
    width: calc((100% - 1140px) / 2 + 500px);
  }
}

@media (max-width: 1199px) {
  .entrance-bg {
    width: calc((100% - 960px) / 2 + 420px);
  }
}

@media (max-width: 991px) {
  .entrance-bg {
    width: calc((100% - 720px) / 2 + 360px);
  }
}

@media (max-width: 767px) {
  .entrance-bg {
    display: none;
  }
}

/* SECTION 11 - Forum */
#sec-11 .stat {
  background-color: #eef3f3;
  font-size: 1.1em;
}

@media (max-width: 1399px) {
  #sec-11 .stat {
    font-size: 1em;
  }
}

@media (max-width: 1199px) {
  #sec-11 .stat {
    font-size: 0.9em;
  }
}

#sec-11 .forum-header,
#sec-11 .forum-section {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1em;
  font-size: 1.1em;
}

@media (max-width: 1659px) {

  #sec-11 .forum-header,
  #sec-11 .forum-section {
    font-size: 1em;
  }
}

@media (max-width: 1199px) {

  #sec-11 .forum-header,
  #sec-11 .forum-section {
    padding: 0.75em;
  }
}

@media (max-width: 575px) {

  #sec-11 .forum-header,
  #sec-11 .forum-section {
    align-items: flex-start;
    padding: 1em 0.5em;
  }
}

#sec-11 .forum-header .icon,
#sec-11 .forum-section .icon {
  width: 90px;
  text-align: center;
}

#sec-11 .forum-header .icon svg,
#sec-11 .forum-section .icon svg {
  width: 2em;
  height: 2em;
}

@media (max-width: 1659px) {

  #sec-11 .forum-header .icon svg,
  #sec-11 .forum-section .icon svg {
    width: 1.75em;
    height: 1.75em;
  }
}

@media (max-width: 1199px) {

  #sec-11 .forum-header .icon svg,
  #sec-11 .forum-section .icon svg {
    width: 1.5em;
    height: 1.5em;
  }
}

@media (max-width: 575px) {

  #sec-11 .forum-header .icon svg,
  #sec-11 .forum-section .icon svg {
    width: 1em;
    height: 1em;
  }
}

@media (max-width: 1659px) {

  #sec-11 .forum-header .icon,
  #sec-11 .forum-section .icon {
    width: 70px;
  }
}

@media (max-width: 1199px) {

  #sec-11 .forum-header .icon,
  #sec-11 .forum-section .icon {
    width: 40px;
  }
}

@media (max-width: 575px) {

  #sec-11 .forum-header .icon,
  #sec-11 .forum-section .icon {
    width: 30px;
  }
}

#sec-11 .forum-header .text,
#sec-11 .forum-section .text {
  width: 591px;
}

@media (max-width: 1659px) {

  #sec-11 .forum-header .text,
  #sec-11 .forum-section .text {
    width: 490px;
  }
}

@media (max-width: 1399px) {

  #sec-11 .forum-header .text,
  #sec-11 .forum-section .text {
    width: 430px;
  }
}

@media (max-width: 1199px) {

  #sec-11 .forum-header .text,
  #sec-11 .forum-section .text {
    width: 410px;
  }
}

@media (max-width: 767px) {

  #sec-11 .forum-header .text,
  #sec-11 .forum-section .text {
    width: 360px;
  }
}

@media (max-width: 575px) {

  #sec-11 .forum-header .text,
  #sec-11 .forum-section .text {
    width: calc(100vw - 54px - 1em);
  }
}

#sec-11 .forum-header .text .info,
#sec-11 .forum-section .text .info {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

#sec-11 .forum-header .text-topic,
#sec-11 .forum-section .text-topic {
  width: 591px;
}

@media (max-width: 1659px) {

  #sec-11 .forum-header .text-topic,
  #sec-11 .forum-section .text-topic {
    width: 490px;
  }
}

@media (max-width: 1399px) {

  #sec-11 .forum-header .text-topic,
  #sec-11 .forum-section .text-topic {
    width: 430px;
  }
}

@media (max-width: 1199px) {

  #sec-11 .forum-header .text-topic,
  #sec-11 .forum-section .text-topic {
    width: 410px;
  }
}

@media (max-width: 767px) {

  #sec-11 .forum-header .text-topic,
  #sec-11 .forum-section .text-topic {
    width: 360px;
  }
}

@media (max-width: 575px) {

  #sec-11 .forum-header .text-topic,
  #sec-11 .forum-section .text-topic {
    width: calc(100vw - 54px - 1em);
  }
}

#sec-11 .forum-header .messages,
#sec-11 .forum-header .latest,
#sec-11 .forum-section .messages,
#sec-11 .forum-section .latest {
  margin-left: 30px;
  text-align: center;
}

@media (max-width: 1659px) {

  #sec-11 .forum-header .messages,
  #sec-11 .forum-header .latest,
  #sec-11 .forum-section .messages,
  #sec-11 .forum-section .latest {
    margin-left: 20px;
  }
}

@media (max-width: 767px) {

  #sec-11 .forum-header .messages,
  #sec-11 .forum-header .latest,
  #sec-11 .forum-section .messages,
  #sec-11 .forum-section .latest {
    margin-left: 15px;
  }
}

@media (max-width: 575px) {

  #sec-11 .forum-header .messages,
  #sec-11 .forum-header .latest,
  #sec-11 .forum-section .messages,
  #sec-11 .forum-section .latest {
    margin-left: 10px;
  }
}

#sec-11 .forum-header .messages,
#sec-11 .forum-section .messages {
  width: 160px;
}

@media (max-width: 1659px) {

  #sec-11 .forum-header .messages,
  #sec-11 .forum-section .messages {
    width: 130px;
  }
}

@media (max-width: 1399px) {

  #sec-11 .forum-header .messages,
  #sec-11 .forum-section .messages {
    width: 100px;
  }
}

@media (max-width: 1199px) {

  #sec-11 .forum-header .messages,
  #sec-11 .forum-section .messages {
    width: 70px;
  }
}

@media (max-width: 767px) {

  #sec-11 .forum-header .messages,
  #sec-11 .forum-section .messages {
    width: 80px;
  }
}

@media (max-width: 575px) {

  #sec-11 .forum-header .messages,
  #sec-11 .forum-section .messages {
    display: none;
  }
}

#sec-11 .forum-header .latest,
#sec-11 .forum-section .latest {
  width: 240px;
}

@media (max-width: 1659px) {

  #sec-11 .forum-header .latest,
  #sec-11 .forum-section .latest {
    width: 200px;
  }
}

@media (max-width: 1399px) {

  #sec-11 .forum-header .latest,
  #sec-11 .forum-section .latest {
    width: 160px;
  }
}

@media (max-width: 1199px) {

  #sec-11 .forum-header .latest,
  #sec-11 .forum-section .latest {
    width: 115px;
  }
}

@media (max-width: 767px) {

  #sec-11 .forum-header .latest,
  #sec-11 .forum-section .latest {
    display: none;
  }
}

#sec-11 .forum-header {
  background: #eef3f3;
}

#sec-11 .forum-section:nth-child(even) {
  background-color: #f6fcfb;
}

#sec-11 .comment {
  display: flex;
  align-items: flex-start;
  padding: 2em;
}

@media (max-width: 1399px) {
  #sec-11 .comment {
    padding: 1.5em;
  }
}

@media (max-width: 991px) {
  #sec-11 .comment {
    flex-wrap: wrap;
  }
}

@media (max-width: 575px) {
  #sec-11 .comment {
    padding: 1em;
  }
}

#sec-11 .comment .user {
  width: 210px;
  text-align: center;
  font-size: 1.1em;
}

#sec-11 .comment .user img {
  width: 110px;
  height: 110px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 1em;
}

@media (max-width: 767px) {
  #sec-11 .comment .user img {
    width: 65px;
    height: 65px;
    margin-right: 2em;
    margin-bottom: 0px;
  }
}

@media (max-width: 575px) {
  #sec-11 .comment .user img {
    margin-right: 0.5em;
    width: 50px;
    height: 50px;
  }
}

@media (max-width: 1399px) {
  #sec-11 .comment .user {
    width: 180px;
    font-size: 1em;
  }
}

@media (max-width: 1199px) {
  #sec-11 .comment .user {
    width: 160px;
  }
}

@media (max-width: 767px) {
  #sec-11 .comment .user {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1em;
    text-align: left;
  }
}

#sec-11 .comment>.text {
  flex: 1;
  margin: 0 1.5em;
  font-size: 1.1em;
}

@media (max-width: 1399px) {
  #sec-11 .comment>.text {
    margin: 0 1em;
    font-size: 1em;
  }
}

@media (max-width: 767px) {
  #sec-11 .comment>.text {
    margin: 0;
  }
}

#sec-11 .comment .btns {
  width: 190px;
  border-left: 1px solid #e0e0e0;
  padding-left: 1em;
  font-family: "Gotham Pro";
  font-size: 1.1em;
}

#sec-11 .comment .btns .answer:hover,
#sec-11 .comment .btns .answer:focus {
  color: #01bfc4;
}

#sec-11 .comment .btns .answer:hover svg,
#sec-11 .comment .btns .answer:focus svg {
  color: #01bfc4;
}

@media (max-width: 991px) {
  #sec-11 .comment .btns .answer {
    order: 4;
    margin-left: auto;
  }
}

#sec-11 .comment .btns .report:hover,
#sec-11 .comment .btns .report:focus {
  color: #d17777;
}

#sec-11 .comment .btns .report:hover svg,
#sec-11 .comment .btns .report:focus svg {
  color: #d17777;
}

@media (max-width: 1399px) {
  #sec-11 .comment .btns {
    width: 170px;
    font-size: 1em;
  }
}

@media (max-width: 1199px) {
  #sec-11 .comment .btns {
    width: 150px;
  }
}

@media (max-width: 991px) {
  #sec-11 .comment .btns {
    width: 100%;
    padding-left: 0px;
    border-left: none;
    display: flex;
    align-items: center;
    margin-top: 1em;
  }
}

#sec-11 .comment blockquote {
  background-color: #d5f6ef;
  padding: 1em;
  border-radius: 0.5em;
  margin-bottom: 0.75em;
  display: flex;
  align-items: flex-start;
}

#sec-11 .comment blockquote>.text {
  flex: 1;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2em;
  height: 3.6em;
}

#sec-11 .comment blockquote>.text .author {
  float: left;
  line-height: 1.2em;
}

#sec-11 .fixed-comment {
  background-color: #f6fcfb;
  padding-bottom: 1.5em;
}

#sec-11 .fixed-comment .answer-to-comment {
  padding: 0 2em;
  margin-left: calc(210px + 1.5em);
  margin-right: calc(190px + 1.5em);
}

@media (max-width: 1399px) {
  #sec-11 .fixed-comment .answer-to-comment {
    margin-left: calc(180px + 1em);
    margin-right: calc(170px + 1em);
    padding: 0 1.5em;
  }
}

@media (max-width: 1199px) {
  #sec-11 .fixed-comment .answer-to-comment {
    margin-left: calc(160px + 1em);
    margin-right: calc(150px + 1em);
  }
}

@media (max-width: 991px) {
  #sec-11 .fixed-comment .answer-to-comment {
    margin-right: 0px;
  }
}

@media (max-width: 767px) {
  #sec-11 .fixed-comment .answer-to-comment {
    margin-left: 0px;
  }
}

@media (max-width: 575px) {
  #sec-11 .fixed-comment .answer-to-comment {
    padding: 0 1em;
  }
}

#sec-11 .answers-box .comment:nth-child(even) {
  background-color: #f6fcfb;
}

#answer blockquote {
  display: flex;
  align-items: flex-start;
}

#answer blockquote>.text {
  flex: 1;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.2em;
  height: 2.4em;
}

#answer blockquote>.text .author {
  float: left;
}

/* SECTION 12 - Personal Account */
#sec-12 .box {
  border: none;
}

@media (max-width: 991px) {
  #sec-12 .box {
    background-color: transparent;
  }
}

#sec-12 .menu ul {
  list-style: none;
  padding-left: 0px;
}

#sec-12 .menu ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.75em 0;
}

#sec-12 .menu ul li:not(:last-child) {
  border-bottom: 1px solid #e0e0e0;
}

@media (max-width: 1199px) {
  #sec-12 .menu ul li {
    padding: 1em 0;
  }
}

#sec-12 .menu ul li a,
#sec-12 .menu ul li button {
  font-size: 1.25em;
  font-weight: 500;
  font-family: "Gotham Pro";
  display: flex;
  align-items: center;
}

#sec-12 .menu ul li a.active,
#sec-12 .menu ul li button.active {
  color: #038cb8;
}

#sec-12 .profile-picture {
  width: 240px;
  height: 240px;
  max-width: 100%;
  border-radius: 50%;
  border: 1px solid #e0e0e0;
  background-color: #fff;
}

@media (max-width: 1399px) {
  #sec-12 .profile-picture {
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 1199px) {
  #sec-12 .profile-picture {
    width: 170px;
    height: 170px;
  }
}

#sec-12 .profile-picture img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#sec-12 .tab-btn {
  border-bottom: 6px solid transparent;
  padding: 0.3em 0;
}

#sec-12 .tab-btn.active {
  border-bottom: 0.4em solid #01bfc4;
}

#sec-12 details {
  border-radius: 8px;
  border: 1px solid #bdbdbd;
  padding: 1em;
}

#sec-12 details[open]>summary h5>svg {
  transform: rotate(-180deg);
}

#sec-12 details summary::-webkit-details-marker,
#sec-12 details summary::marker {
  display: none;
  font-size: 0px;
}

#sec-12 details summary h5>svg {
  transition: 200ms;
}

#sec-12 details table {
  font-size: 0.9em;
}

#sec-12 details table th {
  font-family: "Roboto";
  font-weight: 500;
  padding-right: 2em;
}

@media (max-width: 575px) {
  #sec-12 details table th {
    padding-right: 1em;
  }
}

#sec-12 details table td {
  padding: 0.25em 0;
}


.docs-header {
  border-radius: 8px;
}

#sec-12 .docs-header,
#sec-12 .docs-preview,
#sec-12 .patterns-header,
#sec-12 .patterns-preview {
  display: flex;
  align-items: center;
  background-color: #eef3f3!important;
  padding: 1em 1em;
  font-size: 1.1em;
}

@media (max-width: 1659px) {

  #sec-12 .docs-header,
  #sec-12 .docs-preview,
  #sec-12 .patterns-header,
  #sec-12 .patterns-preview {
    padding: 0.75em 1em;
    background-color: #FFF;
  }
}

@media (max-width: 1399px) {

  #sec-12 .docs-header,
  #sec-12 .docs-preview,
  #sec-12 .patterns-header,
  #sec-12 .patterns-preview {
    font-size: 1em;
  }
}

@media (max-width: 767px) {

  #sec-12 .docs-header,
  #sec-12 .docs-preview,
  #sec-12 .patterns-header,
  #sec-12 .patterns-preview {
    padding: 0.5em 0.75em;
  }
}

#sec-12 .docs-header label,
#sec-12 .docs-header .title,
#sec-12 .docs-header .number,
#sec-12 .docs-header .date,
#sec-12 .docs-header .doc-type,
#sec-12 .docs-header .contractor,
#sec-12 .docs-preview label,
#sec-12 .docs-preview .title,
#sec-12 .docs-preview .number,
#sec-12 .docs-preview .date,
#sec-12 .docs-preview .doc-type,
#sec-12 .docs-preview .contractor,
#sec-12 .patterns-header label,
#sec-12 .patterns-header .title,
#sec-12 .patterns-header .number,
#sec-12 .patterns-header .date,
#sec-12 .patterns-header .doc-type,
#sec-12 .patterns-header .contractor,
#sec-12 .patterns-preview label,
#sec-12 .patterns-preview .title,
#sec-12 .patterns-preview .number,
#sec-12 .patterns-preview .date,
#sec-12 .patterns-preview .doc-type,
#sec-12 .patterns-preview .contractor {
  margin-right: 2em;
}

@media (max-width: 1399px) {

  #sec-12 .docs-header label,
  #sec-12 .docs-header .title,
  #sec-12 .docs-header .number,
  #sec-12 .docs-header .date,
  #sec-12 .docs-header .doc-type,
  #sec-12 .docs-header .contractor,
  #sec-12 .docs-preview label,
  #sec-12 .docs-preview .title,
  #sec-12 .docs-preview .number,
  #sec-12 .docs-preview .date,
  #sec-12 .docs-preview .doc-type,
  #sec-12 .docs-preview .contractor,
  #sec-12 .patterns-header label,
  #sec-12 .patterns-header .title,
  #sec-12 .patterns-header .number,
  #sec-12 .patterns-header .date,
  #sec-12 .patterns-header .doc-type,
  #sec-12 .patterns-header .contractor,
  #sec-12 .patterns-preview label,
  #sec-12 .patterns-preview .title,
  #sec-12 .patterns-preview .number,
  #sec-12 .patterns-preview .date,
  #sec-12 .patterns-preview .doc-type,
  #sec-12 .patterns-preview .contractor {
    margin-right: 1em;
  }
}

@media (max-width: 575px) {

  #sec-12 .docs-header label,
  #sec-12 .docs-header .title,
  #sec-12 .docs-header .number,
  #sec-12 .docs-header .date,
  #sec-12 .docs-header .doc-type,
  #sec-12 .docs-header .contractor,
  #sec-12 .docs-preview label,
  #sec-12 .docs-preview .title,
  #sec-12 .docs-preview .number,
  #sec-12 .docs-preview .date,
  #sec-12 .docs-preview .doc-type,
  #sec-12 .docs-preview .contractor,
  #sec-12 .patterns-header label,
  #sec-12 .patterns-header .title,
  #sec-12 .patterns-header .number,
  #sec-12 .patterns-header .date,
  #sec-12 .patterns-header .doc-type,
  #sec-12 .patterns-header .contractor,
  #sec-12 .patterns-preview label,
  #sec-12 .patterns-preview .title,
  #sec-12 .patterns-preview .number,
  #sec-12 .patterns-preview .date,
  #sec-12 .patterns-preview .doc-type,
  #sec-12 .patterns-preview .contractor {
    margin-right: 0.5em;
  }
}

#sec-12 .docs-header .title,
#sec-12 .docs-preview .title,
#sec-12 .patterns-header .title,
#sec-12 .patterns-preview .title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

#sec-12 .docs-header .number,
#sec-12 .docs-preview .number,
#sec-12 .patterns-header .number,
#sec-12 .patterns-preview .number {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 150px;
  text-align: center;
}

@media (max-width: 1399px) {

  #sec-12 .docs-header .number,
  #sec-12 .docs-preview .number,
  #sec-12 .patterns-header .number,
  #sec-12 .patterns-preview .number {
    width: 120px;
  }
}

@media (max-width: 575px) {

  #sec-12 .docs-header .number,
  #sec-12 .docs-preview .number,
  #sec-12 .patterns-header .number,
  #sec-12 .patterns-preview .number {
    display: none;
  }
}

#sec-12 .docs-header .date,
#sec-12 .docs-preview .date,
#sec-12 .patterns-header .date,
#sec-12 .patterns-preview .date {
  width: 150px;
  text-align: center;
}

@media (max-width: 1399px) {

  #sec-12 .docs-header .date,
  #sec-12 .docs-preview .date,
  #sec-12 .patterns-header .date,
  #sec-12 .patterns-preview .date {
    width: 120px;
  }
}

@media (max-width: 767px) {

  #sec-12 .docs-header .date,
  #sec-12 .docs-preview .date,
  #sec-12 .patterns-header .date,
  #sec-12 .patterns-preview .date {
    display: none;
  }
}

#sec-12 .docs-header .doc-type,
#sec-12 .docs-preview .doc-type,
#sec-12 .patterns-header .doc-type,
#sec-12 .patterns-preview .doc-type {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 250px;
  text-align: center;
}

@media (max-width: 1659px) {

  #sec-12 .docs-header .doc-type,
  #sec-12 .docs-preview .doc-type,
  #sec-12 .patterns-header .doc-type,
  #sec-12 .patterns-preview .doc-type {
    width: 225px;
  }
}

@media (max-width: 1399px) {

  #sec-12 .docs-header .doc-type,
  #sec-12 .docs-preview .doc-type,
  #sec-12 .patterns-header .doc-type,
  #sec-12 .patterns-preview .doc-type {
    width: 200px;
  }
}

@media (max-width: 1199px) {

  #sec-12 .docs-header .doc-type,
  #sec-12 .docs-preview .doc-type,
  #sec-12 .patterns-header .doc-type,
  #sec-12 .patterns-preview .doc-type {
    width: 175px;
  }
}

@media (max-width: 767px) {

  #sec-12 .docs-header .doc-type,
  #sec-12 .docs-preview .doc-type,
  #sec-12 .patterns-header .doc-type,
  #sec-12 .patterns-preview .doc-type {
    width: 130px;
  }
}

@media (max-width: 575px) {

  #sec-12 .docs-header .doc-type,
  #sec-12 .docs-preview .doc-type,
  #sec-12 .patterns-header .doc-type,
  #sec-12 .patterns-preview .doc-type {
    display: none;
  }
}

#sec-12 .docs-header .contractor,
#sec-12 .docs-preview .contractor,
#sec-12 .patterns-header .contractor,
#sec-12 .patterns-preview .contractor {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 200px;
}

@media (max-width: 1659px) {

  #sec-12 .docs-header .contractor,
  #sec-12 .docs-preview .contractor,
  #sec-12 .patterns-header .contractor,
  #sec-12 .patterns-preview .contractor {
    width: 150px;
  }
}

@media (max-width: 1399px) {

  #sec-12 .docs-header .contractor,
  #sec-12 .docs-preview .contractor,
  #sec-12 .patterns-header .contractor,
  #sec-12 .patterns-preview .contractor {
    width: 135px;
  }
}

@media (max-width: 767px) {

  #sec-12 .docs-header .contractor,
  #sec-12 .docs-preview .contractor,
  #sec-12 .patterns-header .contractor,
  #sec-12 .patterns-preview .contractor {
    width: 120px;
  }
}

@media (max-width: 575px) {

  #sec-12 .docs-header .contractor,
  #sec-12 .docs-preview .contractor,
  #sec-12 .patterns-header .contractor,
  #sec-12 .patterns-preview .contractor {
    width: 75px;
  }
}

#sec-12 .docs-header .dropdown>button,
#sec-12 .docs-preview .dropdown>button,
#sec-12 .patterns-header .dropdown>button,
#sec-12 .patterns-preview .dropdown>button {
  width: 2em;
}

#sec-12 .docs-preview,
#sec-12 .patterns-preview {
  background-color: #fff;
  border-radius: 8px;
  border: 1px solid #bdbdbd;
}

#sec-12 .docs-preview:hover,
#sec-12 .docs-preview:focus,
#sec-12 .patterns-preview:hover,
#sec-12 .patterns-preview:focus {
  border: 1px solid #01bfc4;
}

#sec-12 .docs-preview .dropdown button:hover svg,
#sec-12 .patterns-preview .dropdown button:hover svg {
  color: #01bfc4;
}

.response {
  width: 100%;
  border-radius: 0.5em;
  background: #545454;
  color: white;
  box-shadow: 1px 4px 9px rgba(1, 191, 196, 0.34);
  padding: 1em;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.response img {
  width: 5em;
  height: 5em;
  object-fit: cover;
  border-radius: 50%;
}

@media (max-width: 1659px) {
  .response img {
    width: 4em;
    height: 4em;
  }
}

.response .more {
  color: #bdbdbd;
  text-decoration: underline;
  cursor: pointer;
  padding-left: 5px;
}

.response .dropdown {
  position: absolute;
  top: 0.5em;
  right: 0.5em;
}

.response .link-to-product {
  font-size: 1.2em;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.response .link-to-product:hover {
  color: #038cb8;
}

.docs-list {
  list-style: none;
  padding-left: 0px;
}

.docs-list li a {
  display: flex;
  align-items: center;
  width: 100%;
  padding: 0.5em 1em;
  background: transparent;
}

.docs-list li a:hover {
  background: rgba(1, 191, 196, 0.22);
}

.docs-list li a:hover svg {
  color: #01bfc4;
}

.docs-list li a span {
  margin-left: 1em;
}

.tariffs>div:nth-child(5n+1) .tariff {
  border: 3px solid #a5cb80;
  box-shadow: 1px 4px 9px rgba(165, 203, 128, 0.25);
}

.tariffs>div:nth-child(5n+2) .tariff {
  border: 3px solid #01bfc4;
  box-shadow: 1px 4px 9px rgba(1, 191, 196, 0.25);
}

.tariffs>div:nth-child(5n+3) .tariff {
  border: 3px solid #038cb8;
  box-shadow: 1px 4px 9px rgba(3, 140, 184, 0.25);
}

.tariffs>div:nth-child(5n+4) .tariff {
  border: 3px solid #00496f;
  box-shadow: 1px 4px 9px rgba(0, 73, 111, 0.25);
}

.tariffs>div:nth-child(5n+5) .tariff {
  border: 3px solid #7b61ff;
  box-shadow: 1px 4px 9px rgba(123, 97, 255, 0.25);
}

.tariff {
  text-align: center;
  padding: 0.75em;
  border-radius: 8px;
  color: #000;
  background: #ffffff;
  font-size: 1.4em;
  cursor: pointer;
}

@media (max-width: 1659px) {
  .tariff {
    font-size: 1.2em;
  }
}

.tariff__date {
  font-size: 1.25em;
  font-weight: 400;
}

.tariff__date span {
  color: #01bfc4;
  font-weight: 900;
}

.tariff-modal-header {
  display: block;
}

.tariff-modal-header p {
  text-align: center;
}

.tariff-modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 15px;
}

.tariff-modal-body button {
  width: 100%;
}

.tariff-modal-body a {
  width: 100%;
}

footer {
  /* background-color: #575e62; */
  background-color: #545454;
  height: 500px;
  color: #fff;
  font-family: "Gotham Pro";
}

@media (max-width: 1199px) {
  footer {
    height: 480px;
  }
}

@media (max-width: 767px) {
  footer {
    max-height: 480px;
  }
}

footer .logo {
  width: 200px;
  height: 200px;
  object-fit: contain;
  max-width: 100%;
}

@media (max-width: 767px) {
  footer .logo {
    width: 130px;
    height: 130px;
  }
}

footer hr {
  width: 100%;
  height: 2px !important;
  background: #ffffff;
  border: none;
  opacity: 1;
}

footer ul a {
  font-size: 1.2em;
  text-transform: uppercase;
  font-weight: 700;
}

footer .payment-methods {
  display: flex;
  align-items: center;
  justify-content: center;
}

footer .payment-methods img {
  max-height: 60px;
}

@media (max-width: 575px) {
  footer .payment-methods img {
    max-height: 30px;
  }
}

footer .payment-methods img:not(:first-child) {
  margin-left: 2em;
}

@media (max-width: 767px) {
  footer .payment-methods img:not(:first-child) {
    margin-left: 1em;
  }
}

@media (max-width: 575px) {
  footer .payment-methods img:not(:first-child) {
    margin-left: 5px;
  }
}

/* FORUM */
.forum-widget {
  background-color: #fafafa;
  padding: 1em;
}

@media (max-width: 1399px) {
  .forum-widget {
    padding: 0 1em;
  }
}

.forum-widget .message {
  border-bottom: 1px solid #e0e0e0;
  padding: 0.75em 0px;
}

.forum-widget .message h5 {
  color: #545454;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.4em;
}

.forum-widget .message .text {
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 1.1em;
  height: 3.3em;
  font-size: 0.9em;
}

.forum-widget .message .text .author {
  color: #038cb8;
  text-decoration: underline;
}

/* SWIPER SLIDERS */
.swiper-4 {
  overflow: hidden;
  padding: 1em 0 2em;
}

.swiper-4 .swiper-wrapper {
  align-items: stretch;
}

.swiper-4 .swiper-wrapper .swiper-slide {
  height: auto;
}

.swiper-4 .swiper-button-prev,
.swiper-4 .swiper-button-next {
  font-size: 1em;
  color: #038cb8;
  width: 3em;
  height: 3em;
}

.swiper-4 .swiper-button-prev svg,
.swiper-4 .swiper-button-next svg {
  width: 100%;
  height: 100%;
}

.swiper-4 .swiper-button-prev::after,
.swiper-4 .swiper-button-next::after {
  content: unset;
}

@media (max-width: 1199px) {

  .swiper-4 .swiper-button-prev,
  .swiper-4 .swiper-button-next {
    display: none;
  }
}

.swiper-4 .swiper-button-next {
  right: -2.5em;
  left: unset;
}

.swiper-4 .swiper-button-prev {
  left: -2.5em;
  right: unset;
}

@media (max-width: 575px) {
  .swiper-4 .swiper-slide {
    width: 260px;
  }
}

.swiper-button-prev::after,
.swiper-button-next::after {
  content: unset;
}

.swiper-pagination {
  bottom: 0px;
  left: 0px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-pagination-bullet {
  width: 0.75em;
  height: 0.75em;
  background-color: #e0e0e0;
  opacity: 1;
}

.swiper-pagination-bullet:not(:first-child) {
  margin-left: 0.5em;
}

.swiper-pagination-bullet-active {
  background-color: #038cb8;
}

.swiper-container-fade .swiper-slide {
  opacity: 0 !important;
  transition: 0.1s;
  z-index: 1;
}

.swiper-container-fade .swiper-slide-active {
  opacity: 1 !important;
  z-index: 2;
}

/* breadcrumbs */
.breadcrumb {
  font-size: 0.8em;
  margin-bottom: 0px;
}

.breadcrumb-item+.breadcrumb-item::before {
  content: ">";
}

/* pagination */
.pagination {
  justify-content: center;
}

.pagination .page-item {
  width: 2em;
  height: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 0.25em;
}

.pagination .page-item .page-link {
  width: 100%;
  height: 100%;
  color: #828282;
  font-size: 1.2em;
  border-radius: 5px;
  background-color: transparent;
  border: none;
  padding: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.pagination .page-item .page-link svg {
  width: 1.2em;
  height: 1.2em;
}

.pagination .page-item .page-link.active {
  background-color: #038cb8;
  color: #fff;
}

.dropdown,
.dropstart {
  position: relative;
}

.dropdown .dropdown-toggle::before,
.dropdown .dropdown-toggle::after,
.dropstart .dropdown-toggle::before,
.dropstart .dropdown-toggle::after {
  content: unset;
}

.dropdown .dropdown-menu,
.dropstart .dropdown-menu {
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  border: none !important;
  width: max-content;
  padding: 0px;
  font-size: 1em;
}

.dropdown .dropdown-menu li button,
.dropdown .dropdown-menu li a,
.dropstart .dropdown-menu li button,
.dropstart .dropdown-menu li a {
  padding: 0.5em 2em;
  width: 100%;
  text-align: left;
  display: block;
}

.dropdown .dropdown-menu li button:hover,
.dropdown .dropdown-menu li button:focus,
.dropdown .dropdown-menu li a:hover,
.dropdown .dropdown-menu li a:focus,
.dropstart .dropdown-menu li button:hover,
.dropstart .dropdown-menu li button:focus,
.dropstart .dropdown-menu li a:hover,
.dropstart .dropdown-menu li a:focus {
  background: rgba(1, 191, 196, 0.22);
}

/* MODAL WINDOWS */
.modal {
  z-index: 2000;
}

.modal-content {
  background-color: #fff;
  border-radius: 0px;
  border: none;
}

.modal-body {
  padding: 2.5em;
}

@media (max-width: 575px) {
  .modal-body {
    padding: 1.5em;
  }
}

.modal-header {
  border: none;
}

.modal-backdrop {
  z-index: 1999;
}

.modal__header .modal-header {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.modal__header .modal-body {
  padding: 0 2.5em 2.5em 2.5em;
}

.modal__header .modal-body form {
  font-weight: 400;
  font-size: 1.2em;
}

.modal__header .modal-body form label {
  margin: 1em 0 0.5em 0;
}

.modal__rename-template .modal-header {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
}

.modal__deleteRoute .modal-header {
  height: 5em;
  padding: 1em 1em 0 1em;
}

.modal__deleteRoute .modal-body {
  padding: 0;
}

.modal__unArchive .modal-header {
  height: 4em;
  padding: 1em 1em 0 1em;
}

.modal__unArchive .modal-body {
  padding: 0;
}

.modal__patternRoute .modal-header {
  height: 5em;
  padding: 1em 1em 0 1em;
}

.modal__patternRoute .modal-body {
  padding: 0 2.5em 2.5em 2.5em;
}

.modal__patternRoute .modal-body .items {
  height: 30em;
  overflow: auto;
}

.modal__savePattern .modal-header {
  height: 5em;
  padding: 1em 1em 0 1em;
}

.modal__savePattern .modal-body {
  padding: 0 2.5em 2.5em 2.5em;
}

.modal__routeErrorValid .modal-content {
  border-radius: 7px;
}

.modal__routeErrorValid .modal-header {
  height: 4em;
  padding: 1em 1em 0 1em;
}

.modal__routeErrorValid .modal-body {
  padding: 0 2.5em 2.5em 2.5em;
}

.modal__inUserProfile .modal-header {
  display: none;
}

.offcanvas {
  z-index: 2000;
  width: 300px;
}

.offcanvas#warning {
  width: 100%;
  background-color: #d17777;
  color: #fff;
  text-align: center;
  height: fit-content;
  padding: 1.5em;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-bottom: unset;
}

@media (max-width: 575px) {
  .offcanvas {
    width: 100%;
  }
}

.offcanvas-body {
  padding: 2em;
}

.offcanvas-body nav ul {
  list-style: none;
  padding-left: 0px;
}

.offcanvas-body nav ul li {
  text-align: center;
}

.offcanvas-body nav ul li:not(:first-child) {
  margin-top: 1em;
}

.offcanvas-body nav ul li a {
  font-size: 1.3em;
  color: #038cb8;
  text-align: center;
}

.offcanvas-body nav ul li a.active {
  color: #01bfc4;
}

.offcanvas-footer {
  padding: 1em;
  background: #ffffff;
  box-shadow: 0px -2px 4px rgba(0, 0, 0, 0.1);
}

.offcanvas-backdrop {
  z-index: 1999;
}

.btn-close {
  z-index: 2000;
  position: absolute;
  top: 0px;
  right: 0px;
  width: 2.5em;
  height: 2.5em;
  padding: 0px;
  display: flex;
  opacity: 1;
  background: unset;
  color: #01bfc4;
}

.btn-close:hover,
.btn-close:focus {
  opacity: 1;
  color: #038cb8;
}

.btn-close:active {
  opacity: 1;
  color: #02537d;
}

.btn-close svg {
  width: 100%;
  height: 100%;
}

.not-found {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
  padding: 3rem;
}

.not-found__title {
  text-align: center;
  margin: 0;
}

.not-found__link {
  text-align: center;
  padding: 0;
}

.city-container {
  position: relative;
}

.city-popup {
  display: none;
  padding: 20px;
  width: 340px;
  background-color: #fff;
  position: absolute;
  right: 0;
  top: 55px;
  font-size: 14px;
  border-radius: 8px;
  -webkit-box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 4px 4px rgba(0, 0, 0, 0.04), 0 20px 40px rgba(0, 0, 0, 0.08);
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.04), 0 4px 4px rgba(0, 0, 0, 0.04), 0 20px 40px rgba(0, 0, 0, 0.08);
  transition: display 2s linear;
}

.city-popup.show {
  display: block;
}

.city-popup::before {
  content: "\a";
  border-color: transparent transparent #848484;
  border-style: solid;
  border-width: 10px 15px;
  position: absolute;
  right: 25px;
  top: -20px;
}

.city-popup__header {
  margin-bottom: 30px;
}

.city-popup__title {
  font-size: 18px;
}

.city-popup__footer {
  display: flex;
  justify-content: space-between;
}

@media (max-width: 576px) {
  .city-popup__footer button {
    flex: 0 1 auto;
  }
}

@media (max-width: 576px) {
  .city-popup {
    width: 325px;
    right: -50%;
    transform: translateX(20%);
  }

  .city-popup::before {
    right: 125px;
    top: -20px;
  }
}

.custom-select {
  width: 100%;
  max-width: 100%;
  position: relative;
}

.custom-select__toggle {
  width: 100%;
  position: relative;
  z-index: 1;
  text-align: left;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.custom-select__toggle div {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex: 1;
}

.custom-select__toggle div span:not(:empty):before {
  content: ":";
}

.custom-select__toggle svg {
  width: 1.4rem;
  height: 0.75rem;
  fill: none;
}

@media (max-width: 767px) {
  .custom-select__toggle svg {
    width: 1.2em;
  }
}

@media (max-width: 575px) {
  .custom-select__toggle svg {
    width: 1em;
  }
}

.custom-select__toggle svg line {
  stroke: #848484;
  stroke-width: 2px;
}

@media (max-width: 575px) {
  .custom-select__toggle svg line {
    stroke-width: 3px;
  }
}

.custom-select .dropdown-toggle::after {
  content: unset;
}

.custom-select .dropdown-list {
  display: none;
  position: absolute;
  top: calc(100% + 5px);
  left: 0;
  min-width: 100%;
  max-width: 500px;
  max-height: 300px;
  overflow: hidden;
  background: #ffffff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  z-index: 1800;
}

.custom-select .dropdown-list[data-alignment=right] {
  left: unset;
  right: 0;
}

.custom-select .dropdown-list label {
  background-color: white;
}

.custom-select .dropdown-list label.radio-line {
  display: block;
  position: relative;
  z-index: 1;
  text-align: left;
  justify-content: flex-start;
  color: #848484;
  padding: 0.5em 2em;
  cursor: pointer;
  white-space: nowrap;
}

.custom-select .dropdown-list label.radio-line:hover input {
  background-color: #038cb8;
}

.custom-select .dropdown-list label.radio-line:hover div {
  color: #fff;
}

.custom-select .dropdown-list label.radio-line input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  cursor: pointer;
  background: transparent;
  border: none;
  border-radius: 0;
  transition: 0.2s;
}

.custom-select .dropdown-list__inner {
  background-color: #fff;
  max-height: 300px;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.custom-select .dropdown-list form {
  width: 100%;
}

.custom-select .dropdown-list__search {
  width: 100%;
  outline: none;
  border-radius: 0;
  border: none;
  border-bottom: 1px solid rgb(224, 224, 224);
}

.custom-select_city .custom-select__toggle_city div {
  color: #038cb8;
}

.custom-select_city .custom-select__toggle_city svg line {
  stroke: #038cb8;
}

.custom-select_city .dropdown-list {
  width: 260px;
  padding: 0;
}

.custom-select.show .dropdown-list {
  display: block;
}

.email-registration {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.email-registration .button-registration {
  position: absolute;
  font-size: 0.6em;
  top: 3.4em;
  right: 5px;
  background-color: #038cb8;
  border: 1px solid #038cb8;
  padding: 0.5em 1.5em;
  transition: 0.5s;
  border-radius: 8px;
  color: #fff;
}

@media (max-width: 575px) {
  .email-registration .button-registration {
    top: 3.8em;
  }
}

.email-registration .button-registration:hover {
  color: #fff;
  background-color: #02537d;
  border: 1px solid #02537d;
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}

.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}

.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}

.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}

@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(24px, 0);
  }
}

.header__button {
  font-family: "Gotham Pro";
  color: #545454;
  padding: 0.5em 0 0;
  margin: 0 1em;
  font-size: 1.2em;
  font-weight: 500;
  transition: 0.2s;
  height: 100%;
  display: flex;
  align-items: center;
  border-bottom: 8px solid transparent;
}

.header__button.active,
.header__button:hover {
  border-bottom: 8px solid #01bfc4;
}

@media (max-width: 1199px) {

  .header__button.active,
  .header__button:hover {
    border-bottom: 5px solid #01bfc4;
  }
}

@media (max-width: 1199px) {
  .header__button {
    border-bottom: 5px solid transparent;
    margin: 0 0.75em;
  }
}

.form-profile .upload__image-wrapper {
  border: unset;
  padding: 0px;
}

.form-profile .upload__image-wrapper .imgs-box .image-item {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: block;
  margin: 0 auto;
  overflow: hidden;
}

.form-profile .upload__image-wrapper .imgs-box .image-item img {
  width: 100%;
  height: 100%;
}

.form-profile .upload__image-wrapper .imgs-box .image-item__btn-wrapper {
  background-color: rgba(0, 0, 0, 0.35);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.form-profile .upload__image-wrapper .imgs-box .image-item__btn-wrapper button {
  width: fit-content;
  display: flex;
  align-items: center;
}

.form-profile .upload__image-wrapper .imgs-box .image-item__btn-wrapper button img {
  width: 0.9em;
  height: 0.9em;
}

.upload__image-wrapper {
  border: 1px dashed #e0e0e0;
  padding: 1em;
  border-radius: 0.25em;
  min-width: fit-content;
  max-width: 100%;
  height: 100%;
  width: 100%;
}

.upload__image-wrapper .imgs-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 0.75em;
  width: 100%;
  height: 100%;
}

.upload__image-wrapper .imgs-box:empty {
  min-width: 140px;
  min-height: 100px;
  background-size: contain;
  background-position: center center;
}

.upload__image-wrapper .imgs-box .image-item {
  position: relative;
  width: 15.6666666667%;
  margin: 0 1% 1% 0;
}

@media (max-width: 1399px) {
  .upload__image-wrapper .imgs-box .image-item {
    width: 19%;
  }
}

@media (max-width: 1199px) {
  .upload__image-wrapper .imgs-box .image-item {
    width: 24%;
  }
}

@media (max-width: 575px) {
  .upload__image-wrapper .imgs-box .image-item {
    width: 32.3333333333%;
  }
}

.upload__image-wrapper .imgs-box .image-item img {
  position: relative;
  z-index: 1;
  width: 100%;
  height: 100px;
  object-fit: cover;
}

@media (max-width: 1659px) {
  .upload__image-wrapper .imgs-box .image-item img {
    height: 90px;
  }
}

@media (max-width: 575px) {
  .upload__image-wrapper .imgs-box .image-item img {
    height: 23vw;
  }
}

.upload__image-wrapper .imgs-box .image-item__btn-wrapper {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.44);
  z-index: 2;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  opacity: 0;
  transition: 0.2s ease;
  color: #fff;
}

.upload__image-wrapper .imgs-box .image-item__btn-wrapper:hover,
.upload__image-wrapper .imgs-box .image-item__btn-wrapper:focus {
  opacity: 1;
}

.upload__image-wrapper .imgs-box .image-item__btn-wrapper button {
  width: 2em;
  height: 2em;
}

.upload__image-wrapper .imgs-box .image-item__btn-wrapper button.main-img {
  width: 100%;
  font-size: 0.8em;
}

.upload__image-wrapper .imgs-box .image-item__btn-wrapper button img {
  width: 1em;
  height: 1em;
  object-fit: cover;
}

.upload__image-wrapper .imgs-box .image-item .mark {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: auto;
  background-color: rgba(0, 0, 0, 0.44);
  color: #fff;
  font-size: 0.8em;
  text-align: center;
  z-index: 3;
}

.inputCity {
  position: relative;
  width: auto;
}

.mainCity {
  position: absolute;
  width: 100%;
  height: auto;
  background-color: #fff;
  max-height: 300px;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
}

.personalCity {
  height: 100%;
  width: 100%;
  background: #fff;
  display: block;
  z-index: 1;
  text-align: left;
  justify-content: flex-start;
  color: #848484;
  padding: 0.5em 2em;
  cursor: pointer;
  white-space: nowrap;
}

.personalCity:hover {
  background: #038cb8;
  color: #fff;
}

.validate-wrapper_error input {
  border-color: red;
}

.validate-wrapper_error .react-select__control {
  border-color: red !important;
}

.validate-wrapper .validate-error {
  text-transform: lowercase;
  margin-top: 5px;
  white-space: nowrap;
  color: red;
}

.article__title {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.dimensions-validation {
  flex-direction: column;
  gap: 20px;
}

.dimensions-validation>div {
  width: 100%;
}

.card-mini .car-card__actions {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.card-mini .car-card__actions>button {
  font-size: 20px;
  font-weight: 400;
}

.add-cargo_hidden-count {
  justify-content: end !important;
  gap: 10px;
}

.type-select {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.type-select__checkbox label>input {
  margin-right: 20px;
}

.cargo-count_hasnt {
  justify-content: end !important;
  gap: 20px;
}

.alert-report-user {
  right: 5em;
  z-index: 100;
}

.forum-widget__date {
  color: #01bfc4;
  font-size: 1.1em;
  line-height: normal;
}

.submit-alert {
  position: fixed;
  z-index: 34242;
  top: 80px;
  width: 50%;
  min-width: 350px;
  margin: auto;
  text-align: center;
  left: 0;
  right: 0;
  border: unset;
}

.profile-picture {
  width: 240px;
  height: 240px;
  max-width: 100%;
  border-radius: 50%;
  border: 1px solid #e0e0e0;
  background-color: #fff;
}

@media (max-width: 1399px) {
  .profile-picture {
    width: 200px;
    height: 200px;
  }
}

@media (max-width: 1199px) {
  .profile-picture {
    width: 170px;
    height: 170px;
  }
}

.profile-picture img {
  border-radius: 50%;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.pay-information {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  padding: 0 2em 0 2em;
  font-size: initial;
}

.pay-information a {
  color: #038cb8;
}

.contacts {
  font-size: initial;
}

.contacts__first ul {
  line-height: 2em;
  margin: 1em;
  padding: 0;
  text-align: center;
}

.contacts__first ul li {
  list-style-type: none;
}

.contacts__first ul li a {
  color: #038cb8;
}

.contacts__second ul {
  line-height: 2em;
  margin: 1em;
  padding: 0;
  text-align: center;
}

.contacts__second ul li {
  list-style-type: none;
}

.form-control {
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  padding: 0.5em;
  width: 100%;
  line-height: normal;
  box-shadow: unset;
  outline: none;
  display: block;
}

.form-control:focus {
  border: 1px solid #01bfc4;
  box-shadow: none;
  background: transparent !important;
}

.socials-link {
  display: flex;
  align-items: center;
  gap: 10px;
}

.socials-icon {
  width: 40px;
  height: 40px;
  fill: white;
  filter: invert(1)
}

@media (max-width: 1199px) {
  .socials-icon {
    width: 25px;
    height: 25px;
  }
}




.doc-ul {
  list-style-type: none;
}

.doc-ul li {
  padding-top: 10px;
}



.editable-text {
  width: 100%;
  border: 2px solid transparent
}

.editable-text:focus {
  border: 2px solid blue
}

.editable-text.edit {
  border: 2px solid lightblue;
  background: lightblue;
}

.input-block {
  width: 200px;
  height: 40px;
  border: 2px solid lightblue !important;
  background: white;
}


.input-block.close {
  border: 2px solid gray !important;
  color: #444;
}

input.filled {
  background: #5ac1e4 !important;
  color: #FAFAFA;
  font-weight: 500;
}

/* .filled {
  background: #5ac1e4!important;
  color: #FAFAFA;
  font-weight: 500;
} */

.filled-current {
  background: #038cb8;
  color: #FAFAFA;
  font-weight: 500;
  border-color: #FFF;
}

.filled-new {
  border: 2px solid #e4b45a !important;
  background: #e4b45a !important;
  color: #FAFAFA;
  font-weight: 500;
}


.filled-current::-webkit-calendar-picker-indicator,
.filled::-webkit-calendar-picker-indicator,
.filled-new::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23FFF" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>') !important;
}

.form-control:focus::-webkit-calendar-picker-indicator {
  background-image: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 24 24"><path fill="%23000" d="M20 3h-1V1h-2v2H7V1H5v2H4c-1.1 0-2 .9-2 2v16c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2zm0 18H4V8h16v13z"/></svg>') !important;
}

/**/

#msgSendButton>svg {
  border: 2px solid #f9f9f9;
  border-radius: 50%;
  padding: 3px;
  transition: .5s all ease;
}

.btn {
  font-size: .98rem;
}

.btn:disabled {
  color: #000;
  background-color: transparent;
  border: 1px solid #7a7878;
}

.btn-6 {
  border: 1px solid #F3C800;
  background: #F3C800;
  color: #FFF;
}

.btn-6:hover {
  border: 1px solid #F39800;
  background: #F39800;
  color: #FFF;
}

.btn-7 {
  border: 1px solid rgb(238, 28, 0);
  background: rgb(238, 28, 0);
  color: #FFF;
}

.btn-7:hover {
  border: 1px solid rgb(180, 28, 0);
  background: rgb(180, 28, 0);
  color: #FFF;
}

.deal-documents {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.deal-document {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  width: 45%;
}

@media (max-width: 767px) {
  .deal-document {
    width: 100%;
  }

  .deal-documents {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.h5 {
  color: #02537d;
  font-size: 1.25em;
  font-weight: 700;
  font-family: "Gotham Pro";
  /* margin-top: 1rem !important;
  margin-bottom: 1rem !important; */
  text-align: center !important;
}

.modal-content {
  border-radius: 8px;
}

/*
*
* ==========================================
* FOR DEMO PURPOSES
* ==========================================
*
*/
body {
  background-color: #74EBD5;
  background-image: linear-gradient(90deg, #74EBD5 0%, #9FACE6 100%);

  min-height: 100vh;
  min-width: 360px;
}



.text-small {
  font-size: 0.9rem;
}

.messages-box,
.chat-box {
  height: 510px;
  overflow-y: scroll;
}

.rounded-lg {
  border-radius: 0.5rem;
}

input::placeholder {
  font-size: 0.9rem;
  color: #999;
}

.list-group-item {
  border-color: transparent;
}

.list-group-item.active {
  border-color: transparent;
}


:root {
  --send-bg:#5cc4e3;
  --send-color: white;
  --receive-bg: rgb(3, 140, 184);
  /* --receive-bg: rgb(248, 249, 250); */
  /* --receive-bg: gray; */
  --page-background: white;
}

.msg {
  position: relative;
}

.msg:before,
.msg:after {
  content: "";
  position: absolute;
}

.send {
  color: var(--send-color);
  background: var(--send-bg);
  align-self: flex-end;
}

.send:before {
  right: -7px;
  width: 20px;
  background-color: var(--send-bg);
  border-bottom-left-radius: 10px;
  bottom: 0;
  height: 25px;
}

.send:after {
  right: -26px;
  width: 26px;
  background-color: var(--page-background);
  border-bottom-left-radius: 10px;
  bottom: 0;
  height: 25px;
}

.receive {
  background: var(--receive-bg);
  color: black;
  align-self: flex-start;
  border-top-left-radius: 0 !important;
}

.receive:before {
  top: -12px;
  height: 15px;
  left: 0px;
  width: 20px;
  background-color: var(--receive-bg);
  border-top-left-radius: 16px 14px;
}

.receive:after {
  height: 15px;
  /* left: -26px; */
  top: -15px;
  width: 26px;
  background-color: var(--page-background);
  border-top-left-radius: 10px;
}

.pMsg {
  max-height: 50px;
  margin: 0;
  /* Убираем внешний отступ */
  -webkit-line-clamp: 3;
  /* Число отображаемых строк */
  display: -webkit-box;
  /* Включаем флексбоксы */
  -webkit-box-orient: vertical;
  /* Вертикальная ориентация */
  overflow: hidden;
  /* Обрезаем всё за пределами блока */
}

@keyframes fade-out {
  0% {
    opacity: 1;
    transform: translateX(0);
  }

  100% {
    opacity: 0;
    transform: translateX(100px);
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateX(-100px);
  }

  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

.event-exit-active {
  animation: fade-out 500ms forwards;
}

.event-enter-active {
  animation: fade-in 500ms forwards;
}

.fields-groups-container {
  flex-direction: row;
  align-items: center;
  display: flex;
  column-gap: 1em;
  justify-content: center;
  flex-wrap: wrap;
  row-gap: 2em;
}

.dataset-buttons {
  flex-direction: row;
  align-items: center;
  display: flex;
  column-gap: 1em;
}

.form-field-style>* {
  display: flex;
  align-items: center;
  justify-content: left;
}

@media (max-width: 767px) {
  .form-field-style {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width: 991px) {
  .fields-groups-container {
    flex-direction: column;
    display: flex;
  }

  .members-input {
    display: flex;
    flex-direction: column;
    /* row-gap: 1em; */
  }

  .dataset-buttons {
    display: flex;
    flex-direction: column;
    row-gap: 1em;
  }
}

@media (max-width: 991px) {
  .title-main {
    display: flex;
    align-items: center;
    row-gap: .5em;
    flex-direction: column;
  }
}
